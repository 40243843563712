export function lowvision() {
    let textToLowVision = 'Людям з порушеннями зору';
    let textToNormal = 'Звичайна версія';
    let elementChangeVision = document.getElementById('changeVision');
    let elementChangeVisionSpan = document.querySelector('#changeVision span');

    let elementChangeVisionDec = document.getElementById('fontDec');
    let elementChangeVisionInc = document.getElementById('fontInc');

    let fontOption = 0;

    function checkVision() {
        if (localStorage.getItem('fontSize') === null) {
            localStorage.setItem('fontSize', '1');
        } else {
            fontOption = Number(localStorage.getItem('fontSize'));
            checkFont();
        }

        if (localStorage.getItem('lowvision') === 'true') {
            document.body.classList.add('lowvision');
            elementChangeVisionSpan.innerHTML = textToNormal;
        } else {
            document.body.classList.remove('lowvision');
            elementChangeVisionInc.classList.remove('font-btn-dis');
            elementChangeVisionDec.classList.remove('font-btn-dis');
            localStorage.setItem('fontSize', 1);
            elementChangeVisionSpan.innerHTML = textToLowVision;
            checkFont();
        }
    }

    function checkFont() {
        if (localStorage.getItem('lowvision') === 'true') {
            localStorage.setItem('fontSize', fontOption);
        } else {
            localStorage.setItem('fontSize', '1');
            fontOption = 1;
        }

        if (fontOption === 0) {
            document.body.style['font-size'] = "14px";
            elementChangeVisionDec.classList.add('font-btn-dis');
        } else if (fontOption === 1) {
            document.body.style['font-size'] = "";
        } else if (fontOption === 2) {
            document.body.style['font-size'] = "18px";
        } else if (fontOption === 3) {
            document.body.style['font-size'] = "20px";
        } else if (fontOption === 4) {
            document.body.style['font-size'] = "22px";
            elementChangeVisionInc.classList.add('font-btn-dis');
        }
    }

    function changeVision() {
        if (localStorage.getItem('lowvision') === 'true') {
            localStorage.removeItem('lowvision');
        } else {
            localStorage.setItem('lowvision', 'true');
        }
        checkVision();
    }

    elementChangeVisionDec.addEventListener("click", function () {
        fontOption--;
        checkFont();
        elementChangeVisionInc.classList.remove('font-btn-dis');
    });

    elementChangeVisionInc.addEventListener("click", function () {
        fontOption++;
        checkFont();
        elementChangeVisionDec.classList.remove('font-btn-dis');
    });

    elementChangeVision.addEventListener("click", function () {
        changeVision();
    });

    checkVision();
}
